try {
  const maybeLocalstorageOverrides = localStorage.getItem('override.__DivvyEnvironment')

  /* eslint-disable functional/no-conditional-statements */
  if (maybeLocalstorageOverrides) {
    /* eslint-disable no-console */
    console.warn(`--------------------------------------------------
OVERRIDING window.__DivvyEnvironment WITH THE FOLLOWING VARABLES
BASED ON THE LOCALSTORAGE 'override.__DivvyEnvironment' NAMESPACE

${JSON.stringify(JSON.parse(maybeLocalstorageOverrides), null, 2)}

--------------------------------------------------`)

    /* eslint-disable functional/immutable-data */
    window.__DivvyEnvironment = {
      ...window.__DivvyEnvironment,
      ...JSON.parse(maybeLocalstorageOverrides),
    }
  }
} catch {
  console.error('FAILED TO PARSE __DivvyEnvironment') /* eslint-disable-line no-console */
}

// eslint-disable-next-line
import(/* webpackChunkName: "VOYAGER_MOUNT" */ './start.js')

/*
 * These imports are necessary for the app to bootstrap.
 * They also have dependencies that they need to fetch.
 * Starting these before async chunks are fetched saves
 * some load time.
 */
import('@apollo/client')
import('react')
import('react-dom')
import('react-router')
import('react-router-dom')
import('babel-polyfill')
import('@divvy-web/skylab.themes')
import('@divvy-web/skylab.icons')
import('@divvy-web/skylab.form')
import('@divvy-web/utils.log')
import('@bill/pathfinder.bill')
